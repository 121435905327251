<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        Update {{ `"${formData.username || 'an Account'}"` }}
      </v-card-title>
      <AccountForm
        :account-data="formData"
        :is-update-form="true"
        @form-cancel="cancelCreation"
        @form-submit="submitAccount"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Update account
        </template>
      </AccountForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import AccountForm from '../../components/forms/AccountForm';

export default {
  components: {
    AccountForm,
  },

  props: {
    accountId: {
      type: String,
      required: true,
      default: '',
    },
  },

  data: () => ({
    formData: { },
  }),

  async created() {
    const accountData = await this.fetchAccount(this.accountId);
    accountData.scopeIds = accountData.scopeIds.map((s) => s.scopeId);
    this.formData = cloneDeep(accountData);
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('AccountsModule', ['fetchAccount', 'updateAccount']),

    cancelCreation() {
      this.$router.push({ name: 'AccountIndex' });
    },

    async submitAccount(accountData) {
      try {
        const {
          username,
          password,
          scopeIds,
          firstName,
          lastName,
          active,
        } = accountData;
        await this.updateAccount({
          username,
          password,
          scopeIds,
          firstName,
          lastName,
          active,
          accountId: this.accountId,
        });
        this.$router.push({ name: 'AccountIndex' });
      } catch (error) {
        this.setAlert({
          message: `There was an error updating the account
            <strong>"${this.formData.username}"</strong>. Please try again later.`,
        });
      }
    },
  },
};
</script>
