<template>
  <component :is="$route.meta.layout">
    <v-card v-if="pristine">
      <v-skeleton-loader
        data-cy="skeleton-loader"
        :loading="pristine"
        type="table"
      />
    </v-card>

    <v-card
      v-else
      data-cy="rooms-data-card"
    >
      <v-card-title>
        Scopes related to this account
        <v-spacer />
        <v-text-field
          v-model="customParams.username"
          data-cy="rooms-filter"
          append-icon="mdi-magnify"
          name="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        data-cy="rooms-data-table"
        :headers="headers"
        :items="accounts"
        :server-items-length="total"
        :footer-props="{
          itemsPerPageOptions: [15, 30, 50, -1],
        }"
        :items-per-page="pagination.take"
        @pagination="updatePagination"
        @update:items-per-page="updateTake"
        @update:options="updateSort"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="mb-2"
              @click="navigateToCreate"
            >
              Create Account
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr
            data-cy="rooms-data-row"
            class="tw-cursor-pointer"
            @click="item.accountType === 'SUPER' || navigateToUpdate(item.accountId)"
          >
            <td>{{ item.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.firstName || '-' }}</td>
            <td>{{ item.lastName || '-' }}</td>
            <td>{{ item.accountType }}</td>
            <td v-if="!!item.scopes.length">
              <v-chip
                v-for="(scope, index) in item.scopes"
                :key="index"
                color="blue"
                text-color="white"
                link
                small
                class="mr-1"
                @click.stop="navigateToScope(scope.scopeId)"
              >
                {{ scope.name }}
              </v-chip>
            </td>
            <td v-else>
              -
            </td>
            <td v-if="item.accountType !== 'SUPER'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 hover:tw-text-yellow-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="navigateToUpdate(item.accountId)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit Account</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="hover:tw-text-red-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="selectItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete Account</span>
              </v-tooltip>
            </td>
            <td v-else>
              -
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmDialog :active="dialog">
      <template v-slot:title>
        Are you sure?
      </template>

      <template v-slot:text>
        <p>
          This action will delete the account
          "<span
            class="tw-font-bold"
            v-text="selected.username"
          />".
        </p>
        <p class="tw-text-red-500">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="selectItem()"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="removeAccount(selected.accountId)"
        >
          Confirm
        </v-btn>
      </template>
    </ConfirmDialog>
  </component>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import ConfirmDialog from '../../components/ConfirmDialog';
import pagination from '../../helpers/pagination';
import confirms from '../../helpers/confirms';

export default {
  components: {
    ConfirmDialog,
  },

  mixins: [pagination, confirms],

  data: () => ({
    headers: [
      { text: 'Active', sortable: true, value: 'active' },
      { text: 'Username', sortable: true, value: 'username' },
      { text: 'First Name', sortable: true, value: 'firstName' },
      { text: 'Last Name', sortable: true, value: 'lastName' },
      { text: 'Type', sortable: true, value: 'accountType' },
      { text: 'Related Scopes', sortable: false, value: 'scopes' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
  }),

  computed: {
    ...mapState('AccountsModule', ['pristine', 'accounts', 'total']),
  },

  watch: {
    computedParams: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.listAccounts(this.computedParams)
          .catch(() => this.setAlert({
            message: 'There was an error fetching the Accounts. Please try again later.',
          }));
      }, 250),
    },
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('AccountsModule', ['listAccounts', 'deleteAccount']),

    navigateToCreate() {
      this.$router.push({ name: 'AccountCreate' });
    },

    navigateToUpdate(accountId) {
      this.$router.push({ name: 'AccountUpdate', params: { accountId } });
    },

    navigateToScope(scopeId) {
      this.$router.push({ name: 'ScopeUpdate', params: { scopeId } });
    },

    async removeAccount(accountId) {
      try {
        this.selectItem();
        await this.deleteAccount(accountId);
        await this.listAccounts();
      } catch (error) {
        this.setAlert({
          message: 'There was an error deleting the Account. Please try again later.',
        });
      }
    },
  },
};
</script>
