<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="tw-p-4"
  >
    <v-row>
      <v-col sm="12">
        <v-checkbox
          v-model="formData.active"
          label="Account is active?"
          name="account_active"
          hint="Will be used to set accounts to active/inactive."
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-text-field
          v-model="formData.username"
          :rules="formValidation.username"
          label="Username for the Account."
          name="account_username"
          hint="This username will be used to login into the admin panel."
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="formData.firstName"
          :rules="formValidation.firstName"
          label="First Name"
          name="account_firstName"
          persistent-hint
        />
      </v-col>

      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="formData.lastName"
          :rules="formValidation.lastName"
          label="Last Name"
          name="account_lastName"
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="formData.password"
          :rules="shouldNotValidatePassword
            ? [() => true]
            : formValidation.password"
          type="password"
          label="Password for the Account."
          name="account_password"
          hint="This password will be used to login into the admin panel."
          persistent-hint
        />
      </v-col>

      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="passwordConfirm"
          :rules="shouldNotValidatePassword
            ? [() => true]
            : [passwordConfirmValidation()]"
          type="password"
          label="Confirm the password for the Account."
          name="account_password_confirm"
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-autocomplete
          v-model="formData.scopeIds"
          chips
          deletable-chips
          multiple
          :items="scopes"
          item-value="scopeId"
          item-text="name"
          label="Scopes to link to the new Account."
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          <slot name="confirmButtonText" />
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          @click="$emit('form-cancel')"
        >
          <slot name="cancelButtonText" />
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { isEmpty, isString, cloneDeep } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {
    accountData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    valid: false,
    passwordConfirm: '',
    formData: {
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      scopeIds: [],
      active: true,
    },
    formValidation: {
      username: [
        (v) => (!!v || 'Please provide a username.'),
        (v) => (v.indexOf(' ') < 0 || 'The username cannot contain spaces.'),
        (v) => (v.trim().length > 5 || 'The username must be at least 6 characters long.'),
      ],
      firstName: [
        (v) => ((!!v && v.trim().length) || 'First Name is required.'),
      ],
      lastName: [
        (v) => ((!!v && v.trim().length) || 'Last Name is required.'),
      ],
      password: [
        (v) => (new RegExp(/^(?=.*[A-Z])/).test(v) || 'The password must contain at least 1 uppercase alphabetical character.'),
        (v) => (new RegExp(/^(?=.*[0-9])/).test(v) || 'The password must contain at least 1 numeric character.'),
        (v) => (new RegExp(/^(?=.*[!@#$%^&*])/).test(v) || 'The password must contain at least 1 special character.'),
        (v) => (v.length > 7 || 'The password must be at least 8 characters long.'),
      ],
      scopeIds: [
        (v) => ((!!v && v.length) || 'Please select a Scope from the list.'),
        (v) => (v.every(isString) || 'The ID of a Scope is malformed. Reload and try again.'),
      ],
    },
  }),

  computed: {
    ...mapState('ScopesModule', ['scopes', 'pristine']),
    shouldNotValidatePassword() {
      return this.isUpdateForm && isEmpty(this.formData.password);
    },
  },

  watch: {
    accountData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!isEmpty(newVal)) this.formData = cloneDeep(newVal);
      },
    },
  },

  created() {
    if (this.pristine) {
      this.listScopes()
        .catch(() => this.setAlert({ message: 'There was an error fetching the Scopes. Please try again later.' }));
    }
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('ScopesModule', ['listScopes']),

    validate() {
      if (this.$refs.form.validate()) this.$emit('form-submit', { ...this.formData });
    },

    passwordConfirmValidation() {
      return this.passwordConfirm === this.formData.password
        || 'Password confimation does not match the given password.';
    },
  },
};
</script>
