var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.$route.meta.layout,{tag:"component"},[(_vm.pristine)?_c('v-card',[_c('v-skeleton-loader',{attrs:{"data-cy":"skeleton-loader","loading":_vm.pristine,"type":"table"}})],1):_c('v-card',{attrs:{"data-cy":"rooms-data-card"}},[_c('v-card-title',[_vm._v(" Scopes related to this account "),_c('v-spacer'),_c('v-text-field',{attrs:{"data-cy":"rooms-filter","append-icon":"mdi-magnify","name":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.customParams.username),callback:function ($$v) {_vm.$set(_vm.customParams, "username", $$v)},expression:"customParams.username"}})],1),_c('v-data-table',{attrs:{"data-cy":"rooms-data-table","headers":_vm.headers,"items":_vm.accounts,"server-items-length":_vm.total,"footer-props":{
        itemsPerPageOptions: [15, 30, 50, -1],
      },"items-per-page":_vm.pagination.take},on:{"pagination":_vm.updatePagination,"update:items-per-page":_vm.updateTake,"update:options":_vm.updateSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","small":""},on:{"click":_vm.navigateToCreate}},[_vm._v(" Create Account ")])],1)]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{staticClass:"tw-cursor-pointer",attrs:{"data-cy":"rooms-data-row"},on:{"click":function($event){item.accountType === 'SUPER' || _vm.navigateToUpdate(item.accountId)}}},[_c('td',[_vm._v(_vm._s(item.active ? 'Active' : 'Inactive'))]),_c('td',[_vm._v(_vm._s(item.username))]),_c('td',[_vm._v(_vm._s(item.firstName || '-'))]),_c('td',[_vm._v(_vm._s(item.lastName || '-'))]),_c('td',[_vm._v(_vm._s(item.accountType))]),(!!item.scopes.length)?_c('td',_vm._l((item.scopes),function(scope,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"color":"blue","text-color":"white","link":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToScope(scope.scopeId)}}},[_vm._v(" "+_vm._s(scope.name)+" ")])}),1):_c('td',[_vm._v(" - ")]),(item.accountType !== 'SUPER')?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 hover:tw-text-yellow-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToUpdate(item.accountId)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Account")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"hover:tw-text-red-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Account")])])],1):_c('td',[_vm._v(" - ")])])]}}])})],1),_c('ConfirmDialog',{attrs:{"active":_vm.dialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Are you sure? ")]},proxy:true},{key:"text",fn:function(){return [_c('p',[_vm._v(" This action will delete the account \""),_c('span',{staticClass:"tw-font-bold",domProps:{"textContent":_vm._s(_vm.selected.username)}}),_vm._v("\". ")]),_c('p',{staticClass:"tw-text-red-500"},[_vm._v(" This action cannot be undone. ")])]},proxy:true},{key:"cancel",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.selectItem()}}},[_vm._v(" Cancel ")])]},proxy:true},{key:"confirm",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.removeAccount(_vm.selected.accountId)}}},[_vm._v(" Confirm ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }