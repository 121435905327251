<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        Create a new account
      </v-card-title>

      <AccountForm
        @form-cancel="cancelCreation"
        @form-submit="submitAccount"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Add account
        </template>
      </AccountForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AccountForm from '../../components/forms/AccountForm';

export default {
  components: {
    AccountForm,
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('AccountsModule', ['createAccount']),

    cancelCreation() {
      this.$router.push({ name: 'AccountIndex' });
    },

    submitAccount(accountData) {
      this.createAccount(accountData)
        .then(() => this.$router.push({ name: 'AccountIndex' }))
        .catch(() => this.setAlert({
          message: `There was an error creating the account <strong>"${accountData.username}"</strong>. Please try again later.`,
        }));
    },
  },
};
</script>
